<template>
  <div>

    <section class="section is-main-section pb-0 is-align-items-center">
      <div class="box table-filter">
        <div class="is-flex is-align-items-center">
          <div class="table-filter__item">
            <b-field label="Tarih">
              <finance-datepicker :lang="datepicker.datePickerLang" v-mask="'##.##.####'" v-model="date" type="date"
                format="DD.MM.YYYY" />
            </b-field>
          </div>
          <div class="table-filter__item" v-if="!$store.state.user.siteId">
            <b-field label="Site">
              <b-select size="is-small" v-model="siteId">
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in sites">{{ item.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="is-flex is-flex-grow-1 is-align-self-flex-end is-justify-content-end">
            <b-button icon-left="sync" size="is-small" type="is-info" @click="getDashboardDetail">Yenile</b-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="tile is-ancestor is-flex-wrap-wrap">
        <div class="tile is-parent is-3" v-for="(item, key) in totals" :key="key">
          <article class="tile is-child" @click="getTransactions(item.group)">
            <card-component :title="`${item.group}`" :title-class="'has-background-dark has-text-white'">
              <div class="is-widget-label is-align-self-flex-start">
                <h1 class="title">
                  <growing-number :value="parseFloat(item.total)" :prefix="'₺'" />
                </h1>
              </div>
            </card-component>
          </article>
        </div>
      </div>

    </section>
    <section class="section is-main-section p-4">
      <card-component
        :title="`Seçilen grubun yatırım listesi`">
        <b-table ref="table2" style="overflow:auto" :loading="$store.state.loading && $store.state.loading.length > 0"
          :striped="true" :responsive="true" :hoverable="true" :data="transactions">

          <b-table-column label="İşlem" field="actions" sortable v-slot="props">
            <b-button size="is-small" type="is-dark" class="mr-2" @click="logDetail(props.row)">
              Loglar
            </b-button>
          </b-table-column>
          <b-table-column label="Transaction ID" field="uuid" sortable v-slot="props">
            {{ props.row.uuid }}
          </b-table-column>
          <b-table-column label="Process ID" field="processId" sortable v-slot="props">
            {{ props.row.processId }}
          </b-table-column>
          <b-table-column label="Total" field="total" sortable v-slot="props">
            {{ props.row.total }}
          </b-table-column>
          <b-table-column label="Yatırımcı" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column label="Tarih" field="createdAt" sortable v-slot="props">
            {{ dateFormat(props.row.createdAt, dateFormats.normalDateTime) }}
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="$store.state.loading && $store.state.loading.length > 0">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large" />
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import ClientsTableSample from '@/components/ClientsTableSample'
import { ReportService as Service, SiteService } from '@/services'
import { startOfDay, subMinutes } from 'date-fns'
import GrowingNumber from "@/components/GrowingNumber.vue";
import LogModal from '@/views/Deposit/Log'
export default {
  name: 'Home',
  components: {
    CardComponent,
    CardWidget,
    Tiles,
    ClientsTableSample,
    GrowingNumber
  },
  data() {
    return {
      totals: [],
      date: new Date(),
      subMinutes,
      startOfDay,
      sites: [],
      siteId: null,
      transactions: []
    }
  },
  mounted() {
    this.getDashboardDetail()
    if (!this.$store.state.user.siteId) this.getSites()
  },
  methods: {
    getDashboardDetail() {
      this.totals = []
      const startDate = this.getIstanbulDate(this.date)
      const endDate = this.getIstanbulDate(this.date, false)
      Service.bankTransferGroup({ siteId: this.siteId, dates: [startDate, endDate] }).then(res => this.totals = res.data)
    },
    getSites() {
      SiteService.all({ status: 1 }).then(res => this.sites = res.data)
    },
    getTransactions(group){
      this.getDashboardDetail()
      this.transactions = []
      const startDate = this.getIstanbulDate(this.date)
      const endDate = this.getIstanbulDate(this.date, false)
      Service.bankTransferGroupSub({ group, siteId: this.siteId, dates: [startDate, endDate] }).then(res => this.transactions = res.data)
    },
    logDetail(row) {
      this.openModal({
        component: LogModal,
        styles: { width: 1000, height: 'auto' },
        props: {
          withdraw: { ...row },
        },
      })
    }
  },
  watch: {
    'date': function () {
      this.getDashboardDetail()
    },
    'siteId': function () {
      this.getDashboardDetail()
    }
  }
}
</script>

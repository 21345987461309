<template>
	<div>
		<section class="section is-main-section">
			<div class="p-0 box">
				<div class="table-filter m-0">
					<b-field grouped>
						<b-field
							label="Site"
							v-if="
								!$store.state.user.siteId &&
								$store.state.user.group === 'ALL'
							">
							<b-select
								expanded
								size="is-small"
								v-model="form.siteId">
								<option :value="null">-</option>
								<option
									:value="item.uuid"
									v-for="item in sites"
									:key="item.uuid">
									{{ item.name }}
								</option>
							</b-select>
						</b-field>
						<b-field :label="$t('def.approveDate')">
							<finance-datepicker
								:lang="datepicker.datePickerLang"
								v-mask="'##.##.####-##.##.####'"
								v-model="form.dates"
								type="date"
								range-separator="-"
								range
								format="DD.MM.YYYY"
								:shortcuts="datepicker.shortcuts" />
						</b-field>
						<b-field
							class="is-flex is-align-items-end is-flex-grow-1 is-justify-content-end">
							<b-button
								size="is-small"
								icon-left="magnify"
								type="is-info"
								@click="getReports"
								>{{$t('def.filter')}}</b-button
							>
							<b-button
								class="ml-5"
								size="is-small"
								icon-left="plus"
								type="is-warning"
								v-if="hasPermission('Menu.CageTurnover')"
								@click="cageSave"
								>Kasa Devri</b-button
							>
						</b-field>
						<!--
            <b-field class="is-flex is-align-items-end">
              <b-button size="is-small" icon-left="recycle" type="is-warning" @click="filterReset">Sıfırla</b-button>
            </b-field>
            <b-field class="is-flex is-align-items-end" v-if="hasPermission('CageReport.ExportExcel')">
              <b-button icon-left="file-excel" size="is-small" type="is-success" @click="exportExcel">Export Excel
              </b-button>
            </b-field>
            <b-field class="is-flex is-align-items-end" v-if="hasPermission('CageReport.ExportExcel')">
              <b-button icon-left="file-excel" size="is-small" type="is-success" @click="cageDetailExportExcel">Export Detail Excel
              </b-button>
            </b-field>
            -->
					</b-field>
				</div>
			</div>
			<card-component :title="$t('menu.cage')">
				<b-table
					ref="cageTable"
					:loading="
						$store.state.loading && $store.state.loading.length > 0
					"
					:striped="true"
					:responsive="true"
					:hoverable="true"
					default-sort="generalBalance"
					default-sort-direction="desc"
					:data="datas">
					<b-table-column
						:label="$t('def.site')"
						field="siteName"
						sortable
						v-slot="props">
						{{ props.row.siteName }}
					</b-table-column>
					<b-table-column
					:label="$t('menu.deposit')"
						field="deposit"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.deposit) }}
					</b-table-column>
					<b-table-column
					:label="$t('def.manualDeposit')"
						field="manualDeposit"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.manualDeposit) }}
					</b-table-column>
					<b-table-column
					:label="$t('menu.withdraw')"
						field="withdraw"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.withdraw) }}
					</b-table-column>
					<b-table-column
					:label="$t('def.manualWithdraw')"
						field="manualWithdraw"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.manualWithdraw) }}
					</b-table-column>
					<b-table-column
						v-if="$store.state.user.group === 'ALL'"
						:label="$t('def.subTotal')"
						field="subTotal"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.subTotal) }}
					</b-table-column>
					<b-table-column
						v-if="$store.state.user.group === 'ALL'"
						:label="$t('def.totalCommission')"
						field="totalCommission"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.commissionTotal) }}
					</b-table-column>
					<b-table-column
						v-if="$store.state.user.group === 'ALL'"
						:label="$t('def.balance')"
						field="balance"
						sortable
						v-slot="props">
						₺{{ numberFormat(props.row.balance) }}
					</b-table-column>
					<b-table-column
						v-if="$store.state.user.group === 'ALL'"
						:label="$t('def.generalBalance')"
						field="GeneralBalance"
						sortable
						v-slot="props">
						<b-tag
							size="is-small"
							type="is-success"
							v-if="
								parseFloat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								) > 0
							">
							₺{{
								numberFormat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								)
							}}
						</b-tag>
						<b-tag
							size="is-small"
							type="is-danger"
							v-if="
								parseFloat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								) < 0
							">
							₺{{
								numberFormat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								)
							}}
						</b-tag>
						<b-tag
							size="is-small"
							type="is-light"
							v-if="
								parseFloat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								) === 0
							">
							₺
							{{
								numberFormat(
									general.find(
										(item) =>
											item.siteId === props.row.siteId
									).generalBalance
								)
							}}
						</b-tag>
					</b-table-column>
					<section slot="empty" class="section">
						<div class="content has-text-grey has-text-centered">
							<template
								v-if="
									$store.state.loading &&
									$store.state.loading.length > 0
								">
								<p>
									<b-icon
										icon="dots-horizontal"
										size="is-large" />
								</p>
								<p>Fetching data...</p>
							</template>
							<template v-else>
								<p>
									<b-icon
										icon="emoticon-sad"
										size="is-large" />
								</p>
								<p>Nothing's here&hellip;</p>
							</template>
						</div>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>
</template>

<script>
	import CardComponent from "@/components/CardComponent"
	import {
		ReportService as Service,
		DaySummaryService,
		SiteService,
	} from "@/services"

	export default {
		name: "CageReport",
		components: {
			CardComponent,
		},
		data() {
			return {
				datas: [],
				sites: [],
				banks: [],
				form: {
					siteId: null,
					bankId: null,
					bankAccountId: null,
					dates: [new Date(), new Date()],
				},
				general: [],
				detailItems: {},
			}
		},
		computed: {
			getBankAccounts() {
				return this.banks.find((item) => item.uuid === this.form.bankId)
					.accounts
			},
		},
		mounted() {
			if (!this.$store.state.user.siteId) this.getSites()
			// this.getDetail()
			// this.getCageReport()
		},
		methods: {
			getReports() {
				this.detailItems = {}
				this.getCageReport()
				// this.getDetail(this.form.siteId, this.form.bankAccountId, this.form.bankId)
			},
			getDetail(siteId = null, bankAccountId = null, bankId = null) {
				const startDate = this.getIstanbulDate(this.form.dates[0])
				const endDate = this.getIstanbulDate(this.form.dates[1], false)
				const detailParams = {
					siteId,
					bankAccountId,
					bankId,
					dates: [startDate, endDate],
				}
				Service.cageDetail(detailParams).then((res) => {
					res.data.map((item) => {
						if (!this.detailItems[item.siteId])
							this.detailItems[item.siteId] = []
						this.detailItems[item.siteId].push(item)
					})
				})
			},
			getCageReport() {
				const startDate = this.getIstanbulDate(this.form.dates[0])
				const endDate = this.getIstanbulDate(this.form.dates[1], false)
				Service.cagev2({
					...this.form,
					dates: [startDate, endDate],
				}).then((res) => {
					this.datas = res.data.withDate
					this.general = res.data.sites
				})
			},
			getSites() {
				SiteService.all({ status: 1 }).then(
					(res) => (this.sites = res.data)
				)
			},
			cageSave() {
				this.$buefy.dialog.prompt({
					message: `Kasaya devredilecek olan günü seçiniz.`,
					inputAttrs: {
						type: "date",
						value: new Date().toISOString().split("T")[0],
					},
					trapFocus: true,
					onConfirm: (value) => {
						this.$buefy.toast.open(
							`Devredilecek gün: ${this.dateFormat(
								value,
								this.dateFormats.normalDate
							)}`
						)
						DaySummaryService.create({ date: value, sites: this.datas })
							.then((res) => console.log(res.data))
							.catch((err) => console.error(err))
					},
				})
			},
		},
	}
</script>

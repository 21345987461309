<template>
  <div>
    <section class="section is-main-section">
      <div class="p-0 box">
        <div class="table-filter m-0">
          <b-field grouped>
            <b-field :label="$t('def.site')" v-if="!$store.state.user.siteId && $store.state.user.group === 'ALL'">
              <b-select expanded size="is-small" v-model="form.siteId">
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in sites" :key="item.uuid">{{ item.name }}</option>
              </b-select>
            </b-field>
            <b-field :label="$t('def.bank')" v-if="$store.state.user.group === 'ALL'">
              <b-select expanded size="is-small" v-model="form.bankId">
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in banks" :key="item.uuid">{{ item.name }}</option>
              </b-select>
            </b-field>
            <b-field :label="$t('def.accounts')" v-if="form.bankId">
              <b-select expanded size="is-small" v-model="form.bankAccountId">
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in getBankAccounts" :key="item.uuid">{{ item.name }}</option>
              </b-select>
            </b-field>
            <b-field :label="$t('def.approveDate')">
              <finance-datepicker :lang="datepicker.datePickerLang" v-mask="'##.##.####-##.##.####'" v-model="form.dates"
                                  type="date" range-separator="-" range format="DD.MM.YYYY"
                                  :shortcuts="datepicker.shortcuts"/>
            </b-field>
            <b-field class="is-flex is-align-items-end is-flex-grow-1 is-justify-content-end">
              <b-button size="is-small" icon-left="magnify" type="is-info" @click="getReports">{{$t('def.filter')}}</b-button>
            </b-field>
            <!--
            <b-field class="is-flex is-align-items-end">
              <b-button size="is-small" icon-left="recycle" type="is-warning" @click="filterReset">Sıfırla</b-button>
            </b-field>
            <b-field class="is-flex is-align-items-end" v-if="hasPermission('CageReport.ExportExcel')">
              <b-button icon-left="file-excel" size="is-small" type="is-success" @click="exportExcel">Export Excel
              </b-button>
            </b-field>
            <b-field class="is-flex is-align-items-end" v-if="hasPermission('CageReport.ExportExcel')">
              <b-button icon-left="file-excel" size="is-small" type="is-success" @click="cageDetailExportExcel">Export Detail Excel
              </b-button>
            </b-field>
            -->
          </b-field>
        </div>
      </div>
      <card-component :title="$t('menu.cage')">
        <b-table ref="cageTable"
                 :loading="$store.state.loading && $store.state.loading.length > 0"
                 :striped="true"
                 :responsive="true"
                 :hoverable="true"
                 default-sort="GeneralTotal"
                 default-sort-direction="desc"
                 detailed
                 detail-key="SiteId"
                 :data="datas">
          <b-table-column  :label="$t('def.site')" field="site.name" sortable v-slot="props">
            {{ props.row.SiteName }}
          </b-table-column>
          <b-table-column  :label="$t('def.totalDeposit')" field="TotalDeposit" sortable v-slot="props">
            ₺{{ numberFormat(props.row.TotalDeposit) }}
          </b-table-column>
          <b-table-column :label="$t('def.totalWithdraw')" field="TotalWithdraw" sortable v-slot="props">
            ₺{{ numberFormat(props.row.TotalWithdraw) }}
          </b-table-column>
          <b-table-column v-if="$store.state.user.group === 'ALL'" :label="$t('def.subTotal')" field="SubTotal" sortable v-slot="props">
            ₺{{ numberFormat(props.row.SubTotal) }}
          </b-table-column>
          <b-table-column v-if="$store.state.user.group === 'ALL'" :label="$t('def.totalCommission')" field="TotalCommission" sortable v-slot="props">
            ₺{{ numberFormat(props.row.TotalCommission) }}
          </b-table-column>
          <b-table-column v-if="$store.state.user.group === 'ALL'" :label="$t('def.balance')" field="Balance" sortable v-slot="props">
            ₺{{ numberFormat(props.row.Balance) }}
          </b-table-column>
          <b-table-column v-if="$store.state.user.group === 'ALL'" :label="$t('def.generalBalance')" field="GeneralBalance" sortable v-slot="props">
            <b-tag size="is-small" type="is-success" v-if="parseFloat(props.row.GeneralBalance) > 0">
              ₺{{ numberFormat(props.row.GeneralBalance) }}
            </b-tag>
            <b-tag size="is-small" type="is-danger" v-if="parseFloat(props.row.GeneralBalance) < 0">
              ₺{{ numberFormat(props.row.GeneralBalance) }}
            </b-tag>
            <b-tag size="is-small" type="is-light" v-if="parseFloat(props.row.GeneralBalance) === 0">
              ₺ {{ numberFormat(props.row.GeneralBalance) }}
            </b-tag>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <table>
              <thead>
              <tr>
                <th>{{$t('def.cage')}}</th>
                <th>{{$t('def.paymentMethod')}}</th>
                <th>{{$t('def.total')}}</th>
                <th v-if="$store.state.user.group === 'ALL'">{{$t('def.commission')}}</th>
                <th v-if="$store.state.user.group === 'ALL'">{{$t('def.subTotal')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in detailItems[props.row.SiteId]" :key="item.siteId">
                <td>{{ enums.depositTypes[item.method] || '-' }} {{ item.accountGroup && item.accountGroup !== 'ALL' ? `${$store.state.user.group !== 'ALL' ? '' : '('+item.accountGroup+')'}` : '' }}</td>
                <td>
                  <b-tag size="is-small" type="is-success" v-if="item.transactionType === 'DEPOSIT'">{{$t('menu.deposit')}}</b-tag>
                  <b-tag size="is-small" type="is-danger" v-if="item.transactionType === 'WITHDRAW'">{{ $t('menu.withdraw') }}</b-tag>
                </td>
                <td>₺{{ numberFormat(item.total) }}</td>
                <td v-if="$store.state.user.group === 'ALL'">₺{{ numberFormat(item.commission) }}</td>
                <td v-if="$store.state.user.group === 'ALL'">₺{{ numberFormat(item.total - item.commission) }}</td>
              </tr>
              </tbody>
            </table>
          </template>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="$store.state.loading &&  $store.state.loading.length > 0">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large"/>
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"/>
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import { ReportService as Service, BankService, SiteService } from '@/services'

export default {
  name: 'CageReport',
  components: {
    CardComponent
  },
  data () {
    return {
      datas: [],
      sites: [],
      banks: [],
      form: {
        siteId: null,
        bankId: null,
        bankAccountId: null,
        dates: [new Date(), new Date()]
      },
      detailItems: {}
    }
  },
  computed: {
    getBankAccounts () {
      return this.banks.find(item => item.uuid === this.form.bankId).accounts
    }
  },
  mounted () {
    if (!this.$store.state.user.siteId) this.getSites()
    this.getBanks()
    this.getDetail()
    this.getCageReport()
  },
  methods: {
    getReports(){
      this.detailItems = {}
      this.getCageReport()
      this.getDetail(this.form.siteId, this.form.bankAccountId, this.form.bankId)
    },
    getDetail (siteId = null, bankAccountId = null, bankId = null) {
      const startDate = this.getIstanbulDate(this.form.dates[0])
      const endDate = this.getIstanbulDate(this.form.dates[1],false)
      const detailParams = {
        siteId,
        bankAccountId,
        bankId,
        dates: [startDate, endDate]
      }
      Service.cageDetail(detailParams).then(res => {
        res.data.map(item => {
          if (!this.detailItems[item.siteId]) this.detailItems[item.siteId] = []
          this.detailItems[item.siteId].push(item)
        })
      })
    },
    getCageReport () {
      const startDate = this.getIstanbulDate(this.form.dates[0])
      const endDate = this.getIstanbulDate(this.form.dates[1],false)
      Service.cage({
        ...this.form,
        dates: [startDate, endDate]
      }).then(res => {
        this.datas = res.data
      })
    },
    getBanks () {
      BankService.all({status: 1}).then(res => this.banks = res.data)
    },
    getSites () {
      SiteService.all({status: 1}).then(res => this.sites = res.data)
    }
  }
}
</script>

<template>
	<div>
		<section
			class="section is-main-section p-4"
			v-if="
				withdraw.CONFIRMED ||
				withdraw.TAKED ||
				withdraw.DECLINED ||
				withdraw.KICKED
			">
			<div class="tile is-ancestor is-flex-wrap-wrap">
				<div class="tile is-parent is-3" v-if="withdraw.CONFIRMED">
					<article class="tile is-child">
						<card-component
							:title="`Confirmed`"
							:title-class="`has-background-info has-text-white`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.CONFIRMED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number
										:value="withdraw.CONFIRMED.count" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.TAKED">
					<article class="tile is-child">
						<card-component :title="`Taked`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.TAKED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number
										:value="withdraw.TAKED.count" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.DECLINED">
					<article class="tile is-child">
						<card-component
							:title="`Declined`"
							:title-class="`has-background-danger has-text-white`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.DECLINED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number
										:value="withdraw.DECLINED.count" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.KICKED">
					<article class="tile is-child">
						<card-component
							:title="`Maldo İptalleri`"
							:title-class="'has-background-warning has-text-black'">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.KICKED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number
										:value="withdraw.KICKED.total" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
			</div>
		</section>
		<div class="section in-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</div>
	</div>
</template>

<script>
	import Tiles from "@/components/Tiles"
	import CardWidget from "@/components/CardWidget"
	import CardComponent from "@/components/CardComponent"
	import ClientsTableSample from "@/components/ClientsTableSample"
	import GrowingNumber from "@/components/GrowingNumber.vue"
	import { MaldoService as Service, DownloadService } from "@/services"
	import SettingModal from "./SettingModal"
	export default {
		name: "MaldoWithdrawList",
		components: {
			CardComponent,
			CardWidget,
			Tiles,
			ClientsTableSample,
			GrowingNumber,
		},
		data() {
			return {
				withdraw: { TAKED: 0, CONFIRMED: 0, DECLINED: 0, PAYED: 0 },
				table: {
					filter: [
						{
							name: "Process ID",
							type: "input",
							field: "processId",
						},
						{
							name: "User ID",
							type: "input",
							field: "userId",
						},
						{
							name: "Amount",
							type: "input",
							field: "amount",
						},
						{
							name: "Name",
							type: "input",
							field: "name",
						},
						{
							name: "IBAN",
							type: "input",
							field: "iban",
						},
						{
							name: "Status",
							type: "select",
							field: "status",
							data: [
								{ uuid: "TAKED", name: "Take edildi" },
								{ uuid: "CONFIRMED", name: "Confirme edildi" },
								{ uuid: "DECLINED", name: "Decline edildi" },
								{ uuid: "DELETED", name: "Silindi" },
								{
									uuid: "KICKED",
									name: "Çekim maldo tarafından geri alındı",
								},
								{
									uuid: "CONFIRM_WAITING",
									name: "Confirm onayı bekleniyor",
								},
								{
									uuid: "DECLINE_WAITING",
									name: "Decline onayı bekleniyor",
								},
							],
						},
						{
							type: "datepicker",
							value: [new Date(), new Date()],
							field: "dates",
							name: "Talep T.",
						},
						{
							type: "datepicker",
							value: [null, null],
							field: "updatedDates",
							name: "Onay T.",
						},
					],
					page: {
						title: "Çekim Listesi",
						icon: "bank",
						actions: [
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: async () => {
									this.$refs.table.loadAsyncData()
									this.getReportTrigger()
								},
							},
							{
								title: "Ayarlar",
								type: "dark",
								icon: "cogs",
								action: () => {
									this.openModal({
										component: SettingModal,
										styles: { height: "unset" },
									})
								},
							},
							{
								title: "Excel",
								type: "success",
								icon: "file-excel",
								action: () => {
									this.getExportExcel()
								},
							},
							{
								title: "SYNC",
								type: "warning",
								icon: "sync",
								action: async () => {
									this.$socket.emit(
										"maldo socket connect2disconnect",
										this.$store.state.user.username
									)
								},
							},
							{
								title: "Connect",
								type: "success",
								icon: "check",
								action: async () => {
									this.$socket.emit(
										"maldo socket connect",
										this.$store.state.user.username
									)
								},
							},
							{
								title: "Disconnect",
								type: "danger",
								icon: "times",
								action: async () => {
									this.$socket.emit(
										"maldo socket disconnect",
										this.$store.state.user.username
									)
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "withdrawList",
						columns: [
							{
								field: "processId",
								label: "Process ID",
								sortable: true,
							},
							{
								field: "userId",
								label: "User ID",
							},
							{
								field: "name",
								label: "Name",
							},
							{
								field: "iban",
								label: "IBAN",
							},
							{
								field: "amount",
								label: "Ücret",
								column: "row",
								width: "200px",
								renderHtml: true,
								viewMethod: (val) => {
									return `₺${this.numberFormat(val.amount)}`
								},
							},
							{
								field: "status",
								label: "Status",
								viewMethod: (val) =>
									this.enums.maldoWithdrawStatuses[val],
							},
							{
								field: "reason",
								label: "Reason",
							},
							{
								field: "createdAt",
								label: "Kayıt T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTime
									),
							},
							{
								field: "updatedAt",
								label: "Güncelleme T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTime
									),
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								sortable: false,
								width: "100px",
								actions: [
									{
										title: "Confirm",
										type: "success",
										column: "row",
										perm: "Menu.TelegramSetting",
										condition: {
											field: "status",
											value: "TAKED",
										},
										action: (data) => {
											this.$buefy.dialog.prompt({
												message: `Onay açıklaması`,
												inputAttrs: {
													placeholder: ".",
													maxlength: 100,
													value: ".",
												},
												trapFocus: true,
												onConfirm: (value) => {
													Service.withdrawUpdate({
														id: data.processId,
														status: "CONFIRM_WAITING",
														reason: value,
													}).then(() => {
														this.$buefy.toast.open({
															message: `Confirm gönderildi`,
															type: "is-success",
														})
														this.$socket.emit(
															"maldo confirm withdraw",
															{
																type: "payouts_2040",
																transaction_id:
																	data.processId,
																confirmReason:
																	value,
															}
														)
													})
												},
											})
										},
									},
									{
										column: "row",
										title: "Decline",
										type: "danger",
										perm: "Menu.TelegramSetting",
										condition: {
											field: "status",
											value: "TAKED",
										},
										action: (data) => {
											this.$buefy.dialog.prompt({
												message: `İptal Sebebi?`,
												inputAttrs: {
													placeholder:
														"İsim uyuşmazlığı vb.",
													maxlength: 100,
												},
												trapFocus: true,
												onConfirm: (value) => {
													if (
														value === null ||
														value === "" ||
														value === "." ||
														value === " "
													)
														return
													Service.withdrawUpdate({
														id: data.processId,
														status: "DECLINE_WAITING",
														reason: value,
													}).then(() => {
														this.$buefy.toast.open({
															message: `Decline gönderildi.`,
															type: "is-success",
														})
														this.$socket.emit(
															"maldo decline withdraw",
															{
																type: "payouts_2040",
																transaction_id:
																	data.processId,
																cancelReason:
																	value,
															}
														)
													})
												},
											})
										},
									},
									{
										column: "row",
										title: "Decline'a Zorla'",
										type: "warning",
										perm: "Menu.TelegramSetting",
										condition: {
											field: "status",
											value: "DECLINE_WAITING",
										},
										action: (data) => {
											this.$buefy.dialog.prompt({
												message: `İptal Sebebi?`,
												inputAttrs: {
													placeholder:
														"İsim uyuşmazlığı vb.",
													maxlength: 100,
												},
												trapFocus: true,
												onConfirm: (value) => {
													if (
														value === null ||
														value === "" ||
														value === "." ||
														value === " "
													)
														return
													Service.withdrawUpdate({
														id: data.processId,
														status: "DECLINED",
														reason: value,
													}).then(() => {
														this.$buefy.toast.open({
															message: `Decline gönderildi.`,
															type: "is-success",
														})
														this.$socket.emit(
															"maldo decline withdraw",
															{
																type: "payouts_2040",
																transaction_id:
																	data.processId,
																cancelReason:
																	value,
															}
														)
													})
												},
											})
										},
									},
									{
										title: "Confirm'e Zorla",
										type: "warning",
										column: "row",
										perm: "Menu.TelegramSetting",
										condition: {
											field: "status",
											value: "CONFIRM_WAITING",
										},
										action: (data) => {
											this.$buefy.dialog.prompt({
												message: `Onay açıklaması`,
												inputAttrs: {
													placeholder: ".",
													maxlength: 100,
													value: ".",
												},
												trapFocus: true,
												onConfirm: (value) => {
													Service.withdrawUpdate({
														id: data.processId,
														status: "CONFIRMED",
														reason: value,
													}).then(() => {
														this.$buefy.toast.open({
															message: `Confirm gönderildi`,
															type: "is-success",
														})
														this.$socket.emit(
															"maldo confirm withdraw",
															{
																type: "payouts_2040",
																transaction_id:
																	data.processId,
																confirmReason:
																	value,
															}
														)
													})
												},
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
		computed: {
			startDate() {
				return this.$refs.table.filter[
					this.$refs.table.filter[6] ? 6 : 7
				].value[0]
			},
			endDate() {
				return this.$refs.table.filter[
					this.$refs.table.filter[6] ? 6 : 7
				].value[1]
			},
		},
		mounted() {
			if (this.$store.state.setting.socket) {
				this.insert()
				this.update()
			}
			this.getReportTrigger()
		},
		methods: {
			getReportTrigger() {
				let startDate = null
				let endDate = null
				let updatedStartDate = null
				let updatedEndDate = null
				this.$refs.table.filter.map((item) => {
					if (
						(item.value !== "" && item.value !== null) ||
						item.hidden === true
					) {
						if (item.type === "datepicker") {
							if (
								typeof item.value === "object" &&
								item.value.length > 0
							) {
								if (item.value[0] && item.value[1]) {
									if (item.field === "dates") {
										startDate = this.getIstanbulDate(
											item.value[0]
										)
										endDate = this.getIstanbulDate(
											item.value[1],
											false
										)
									}

									if (item.field === "updatedDates") {
										updatedStartDate = this.getIstanbulDate(
											item.value[0]
										)
										updatedEndDate = this.getIstanbulDate(
											item.value[1],
											false
										)
									}
								}
							}
						}
					}
				})
				this.getReport(startDate, endDate, updatedStartDate, updatedEndDate)
			},
			insert() {
				this.sockets.subscribe("maldoWithdrawalInsert", (val) => {
					val = JSON.parse(val)
					const processId = this.$refs.table.filter[0].value || null
					const userId = this.$refs.table.filter[1].value || null
					const name = this.$refs.table.filter[2].value || null
					const amount = this.$refs.table.filter[3].value || null
					const iban = this.$refs.table.filter[4].value || null
					if (
						(!processId || val.processId === processId) &&
						(!userId || val.userId === userId) &&
						(!name || val.name === name) &&
						(!amount || val.amount === amount) &&
						(!iban || val.iban === iban)
					) {
						if (!this.$refs.table.loading && this.$refs.table.data)
							this.$refs.table.data.unshift(val)
						// this.$refs.table.data.pop()
					}
					let newDatas = {
						PAYED: [],
						CONFIRM_WAITING: [],
						TAKED: [],
						CONFIRMED: [],
						DECLINED: [],
						DECLINE_WAITING: [],
						DELETED: [],
					}
					this.$refs.table.data.map((item) =>
						newDatas[item.status].push(item)
					)

					newDatas["TAKED"].sort((a, b) => a.createdAt - b.createdAt)
					newDatas["CONFIRM_WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["CONFIRMED"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PAYED"].sort((a, b) => a.createdAt - b.createdAt)
					newDatas["DECLINE_WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["DECLINED"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["DELETED"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["TAKED"])
							.concat(newDatas["CONFIRM_WAITING"])
							.concat(newDatas["CONFIRMED"])
							.concat(newDatas["PAYED"])
							.concat(newDatas["DECLINE_WAITING"])
							.concat(newDatas["DECLINED"])
							.concat(newDatas["DELETED"]),
					]

					this.getReportTrigger()
				})
			},
			update() {
				this.sockets.subscribe("maldoWithdrawalUpdate", (val) => {
					val = JSON.parse(val)
					if (
						!val.status ||
						!val.processId ||
						this.$refs.table.loading
					)
						return
					let newDatas = {
						PAYED: [],
						CONFIRM_WAITING: [],
						TAKED: [],
						CONFIRMED: [],
						DECLINED: [],
						DECLINE_WAITING: [],
					}
					this.$refs.table.data.map((item, key) => {
						if (item.processId === val.processId) {
							item.status = val.status
							item.updatedAt = new Date(val.updatedAt)
						}
						newDatas[item.status].push(item)
					})
					newDatas["TAKED"].sort((a, b) => a.createdAt - b.createdAt)
					newDatas["CONFIRM_WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["CONFIRMED"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PAYED"].sort((a, b) => a.createdAt - b.createdAt)
					newDatas["DECLINE_WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["DECLINED"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["TAKED"])
							.concat(newDatas["CONFIRM_WAITING"])
							.concat(newDatas["CONFIRMED"])
							.concat(newDatas["PAYED"])
							.concat(newDatas["DECLINE_WAITING"])
							.concat(newDatas["DECLINED"]),
					]

					this.getReportTrigger()
				})
			},
			unsubscribe() {
				this.sockets.unsubscribe("maldoWithdrawalInsert")
				this.sockets.unsubscribe("maldoWithdrawalUpdate")
			},
			async getReport(startDate = null, endDate = null, updatedStartDate = null, updatedEndDate = null) {
				let where = {}
				if(startDate && endDate) {
					where.dates = [startDate, endDate]
				}
				if(updatedStartDate && updatedEndDate) {
					where.updatedDates = [updatedStartDate, updatedEndDate]
				}
				Service.settingReport(where).then((res) => {
					res.data.map(
						(item) =>
							(this.withdraw[item.status] = {
								status: item.status,
								count: parseInt(item.count),
								total: parseFloat(item.total),
							})
					)
				})
			},
			getExportExcel() {
				let filterParams = {}
				if (
					this.$refs.table.filter &&
					this.$refs.table.filter.length > 0
				) {
					this.$refs.table.filter.map((item) => {
						if (
							(item.value !== "" && item.value !== null) ||
							item.hidden === true
						) {
							if (item.type === "datepicker") {
								if (
									typeof item.value === "object" &&
									item.value.length > 0
								) {
									if (item.value[0] && item.value[1]) {
										const startDate = this.getIstanbulDate(
											item.value[0]
										)
										const endDate = this.getIstanbulDate(
											item.value[1],
											false
										)
										filterParams[item.field] = [
											startDate,
											endDate,
										]
									}
								} else {
									filterParams[item.field] = item.value
								}
							} else {
								filterParams[item.field] = item.value
							}
						}
					})
				}

				Service.withdrawExportExcel({
					...filterParams,
					offset: 180,
				}).then(({ data: { path, filename } }) => {
					DownloadService.download({ path }).then((res2) => {
						const url = URL.createObjectURL(
							new Blob([res2.data], {
								type: "application/vnd.ms-excel",
							})
						)
						const link = document.createElement("a")
						link.href = url
						link.setAttribute("download", filename)
						document.body.appendChild(link)
						link.click()
					})
				})
			},
		},
		beforeDestroy() {
			if (this.$store.state.setting.socket) this.unsubscribe()
		},
	}
</script>
